<main class="main relative h-full flex items-center">
  <app-menu-locale [cornerPosition]="true"></app-menu-locale>
  <section class="h-full bg-grey-100 w-3/12">
    <div [ngClass]="{'smartgp-logo': isOtherCompany}">
      <img [src]="isOtherCompany ? '../../assets/img/logo/smartgp-logo.png' : '../../assets/img/logo/logo.png'" [ngClass]="isOtherCompany ? 'w-1/2' : 'ml-8 mt-8'"/>
    </div>
    <h1 class="my-2 mx-8 overline-o1 primary-text">{{'login.title' | translate }}</h1>
    <hr class="divider w-full">
    <form [formGroup]="loginForm" (ngSubmit)="sendLogin()" class="flex flex-col px-8 py-4 relative">
      <app-input [type]="'text'" [control]="loginForm.get('email')" [label]="('login.email' | translate) + '*'" [placeholder]="'login.emailPlaceholder' | translate" data-test="email"></app-input>
      <app-input [type]="'password'" [control]="loginForm.get('password')" [label]="('login.pass' | translate) + '*'" data-test="password"></app-input>
      <a (click)="openRecoveryPassword()" class="link -mt-1 mb-2">{{'login.forgot' | translate}}</a>
      <hr class="divider w-full absolute block left-0 right-0 bottom-16">
      <app-button-secondary [type]="'submit'" class="mt-6 mr-2" data-test="submit">{{'login.login' | translate}}</app-button-secondary>
    </form>
    <a routerLink="/onboarding" class="link block mb-4 ml-8">{{'login.registration' | translate}}</a>
    <app-button-secondary routerLink="/login-sso" class="m-8">{{'login.loginSSO' | translate}}</app-button-secondary>
  </section>
  <div [ngClass]="isOtherCompany ? 'smartgp-background' : 'main-background'" class="h-full w-full">
  </div>
</main>
