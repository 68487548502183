<!--Este componente un login por debajo y si va correcto hace un redirect-->

<main class="main relative h-full flex items-center">
  <app-menu-locale [cornerPosition]="true"></app-menu-locale>
  <section class="h-full bg-grey-100 w-3/12">
    <div>
      <img [src]="'../../assets/img/logo/logo.png'" class="ml-8 mt-8"/>
    </div>
    <h1 class="my-2 mx-8 overline-o1 primary-text">{{'login.title' | translate }}</h1>
    <hr class="divider w-full">
    <form (ngSubmit)="sendSSO()" class="mx-8 mb-4">
      <app-input [type]="'text'" [control]="loginSSOForm" [label]="('login.domain' | translate) + '*'" [placeholder]="'login.domainPlaceholder' | translate" class="mb-2"></app-input>
      <app-button-secondary [disabled]="loginSSOForm.invalid" (click)="sendSSO()" [type]="'submit'" class="mt-6" data-test="submit">{{'login.loginSSO' | translate}}</app-button-secondary>
    </form>
    <app-button-link iconLeft="chevron-left" [text]="'buttons.back' | translate" class="ml-8" routerLink="/login"></app-button-link>
  </section>
  <div class="h-full w-full main-background">
  </div>
</main>
