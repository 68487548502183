import { NgOptimizedImage } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SegmentModule, SegmentService } from 'ngx-segment-analytics';

import { EventService } from '../../../bo/src/app/core/services/event.service';
import { HelperService } from '../../../bo/src/app/core/services/helper.service';
import { HttpCustomLoader, MyMissingTranslationHandler } from '../../../common/i18n/common-i18n';
import { MyCommonModule } from '../../../common/my-common.module';
import { LoadIconsService } from '../../../common/services/load-icons.service';
import { MainCacheService } from '../../../common/shared/main-chache.service';
import { MainEnvService } from '../../../common/shared/main-env.service';
import { MainEventService } from '../../../common/shared/main-event.service';
import { MainHelperService } from '../../../common/shared/main-helper.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { NewPasswordModalComponent } from './components/new-password/new-password-modal.component';
import { NotificationCardComponent } from './components/notifications/notification-card/notification-card.component';
import { NotificationListComponent } from './components/notifications/notification-list/notification-list.component';
import { CacheService } from './core/services/cache.service';
import { EnvService } from './core/services/env.service';
import { ChatDetailComponent } from './routes/chats/chat-detail/chat-detail.component';
import { ChatInvitationsComponent } from './routes/chats/chat-invitations/chat-invitations.component';
import { ChatListComponent } from './routes/chats/chat-list/chat-list.component';
import { CollaboratorsModalComponent } from './routes/chats/collaborators-modal/collaborators-modal.component';
import { SelectRecordModalComponent } from './routes/chats/select-record-modal/select-record-modal.component';
import { SelfInviteModalComponent } from './routes/chats/self-invite-modal/self-invite-modal.component';
import { FavoritesComponent } from './routes/favorites/favorites.component';
import { FavoritesModalComponent } from './routes/favorites/favorites-modal/favorites-modal.component';
import { HomeComponent } from './routes/home/home.component';
import { IframeComponent } from './routes/iframe/iframe.component';
import { LoginComponent } from './routes/login/login.component';
import { PasswordRecoveryComponent } from './routes/login/password-recovery/password-recovery.component';
import { ValidateInvitationComponent } from './routes/login/validate-invitation/validate-invitation.component';
import { LoginGuestComponent } from './routes/login-guest/login-guest.component';
import { LoginSsoComponent } from './routes/login-sso/login-sso.component';
import { OnboardingComponent } from './routes/onboarding/onboarding.component';
import { ValidateEmailModalComponent } from './routes/onboarding/validate-email/validate-email-modal.component';
import { NotificationsConfigurationComponent } from './routes/profile/notifications-configuration/notifications-configuration.component';
import { DigitalIdentityModalComponent } from './routes/profile/organization/digital-identity-modal/digital-identity-modal.component';
import { OrganizationComponent } from './routes/profile/organization/organization.component';
import { OrganizationDataComponent } from './routes/profile/organization/organization-data/organization-data.component';
import { OrganizationMemberComponent } from './routes/profile/organization/organization-member/organization-member.component';
import { NewEventTypeComponent } from './routes/profile/organization-events/new-event-type/new-event-type.component';
import { OrganizationEventsComponent } from './routes/profile/organization-events/organization-events.component';
import { OrganizationPermissionsComponent } from './routes/profile/organization-permissions/organization-permissions.component';
import { OrganizationUsageComponent } from './routes/profile/organization-usage/organization-usage.component';
import { ProfileComponent } from './routes/profile/profile.component';
import { UserDataComponent } from './routes/profile/user-data/user-data.component';
import { IterableCellComponent } from './routes/record-list/iterable-cell/iterable-cell.component';
import { RecordListComponent } from './routes/record-list/record-list.component';
import { TemplateSelectionModalComponent } from './routes/record-list/template-selection-modal/template-selection-modal.component';
import { RecordNotFoundComponent } from './routes/record-not-found/record-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginGuestComponent,
    LoginSsoComponent,
    PasswordRecoveryComponent,
    HomeComponent,
    ProfileComponent,
    OnboardingComponent,
    RecordListComponent,
    FooterComponent,
    OrganizationComponent,
    ValidateEmailModalComponent,
    ValidateInvitationComponent,
    NewPasswordModalComponent,
    UserDataComponent,
    OrganizationDataComponent,
    OrganizationMemberComponent,
    NotificationsConfigurationComponent,
    OrganizationPermissionsComponent,
    TemplateSelectionModalComponent,
    RecordNotFoundComponent,
    FavoritesComponent,
    FavoritesModalComponent,
    NotificationCardComponent,
    NotificationListComponent,
    ChatListComponent,
    SelectRecordModalComponent,
    CollaboratorsModalComponent,
    ChatDetailComponent,
    SelfInviteModalComponent,
    ChatInvitationsComponent,
    OrganizationUsageComponent,
    OrganizationEventsComponent,
    NewEventTypeComponent,
    IterableCellComponent,
    DigitalIdentityModalComponent,
    IframeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MyCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: HttpCustomLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
    SegmentModule.forRoot({ apiKey: '', debug: false, loadOnInitialization: false }),
    NgOptimizedImage,
    // En este punto no hay APIKEY cargada de la conf
  ],
  providers: [
    EnvService,
    {
      provide: APP_INITIALIZER,
      useFactory: (env: EnvService, loadIcons: LoadIconsService, cache: CacheService, segment: SegmentService) => async () => {
        await env.setEnv();
        if (env.segmentKey) segment.load(env.segmentKey, {}); // En este punto si esta cargada la conf del entorno por lo que se inicializa segment
        await loadIcons.loadIcons();
        await cache.init();
      },
      multi: true,
      deps: [EnvService, LoadIconsService, CacheService, SegmentService],
    },
    // Con esto le inyectamos al CommonModule una instancia de los servicios del front. Es necesario usar el factory para que se mantenga la misma instancia incializada
    { provide: MainEnvService, useFactory: (env: EnvService) => env, deps: [EnvService] },
    { provide: MainEventService, useFactory: (env: EventService) => env, deps: [EventService] },
    { provide: MainCacheService, useFactory: (env: CacheService) => env, deps: [CacheService] },
    { provide: MainHelperService, useFactory: (env: HelperService) => env, deps: [HelperService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
