import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, noop, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DEFAULT, MODAL_WIDTH, MODAL_WIDTH_SMALL } from '../../../../../common/models/constants';
import { DashboardEntity, EVENT_STATUS, EventMetadata, LimitMessage, PERMISSION, ROLES, TRANSPORT_TYPES, User, VIEWS_TYPE } from '../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../common/services/api.service';
import { CacheService } from '../../core/services/cache.service';
import { EventService } from '../../core/services/event.service';
import { HelperService } from '../../core/services/helper.service';
import { EventMetadataModalComponent } from '../../modules/record-detail-module/components/event-card/event-metadata-modal/event-metadata-modal.component';
import { SaveTemplateModalComponent } from '../../modules/record-detail-module/record-canvas/save-template-modal/save-template-modal.component';
import { TemplateSelectionModalComponent } from './template-selection-modal/template-selection-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RecordListComponent implements AfterViewInit {

  Helper = HelperService;
  PERMISSION = PERMISSION;
  TRANSPORT_TYPES = TRANSPORT_TYPES;
  VIEWS_TYPE = VIEWS_TYPE;
  EVENT_STATUS = EVENT_STATUS;
  ROLES = ROLES;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  query: CustomQuery = { filter: { status: 'active' }, sort: [], pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  archived: boolean = false;
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  totalElements = 0;
  isGuest: boolean = true;
  user!: User;
  limit: LimitMessage | null = null;
  selectFilterControl = new FormControl('all');
  personalizedColumnsControl: FormControl<string[]> = new FormControl([], { nonNullable: true });
  filters: string[] = ['createdByMe', 'ofMyOrg', 'ofOtherOrg'];
  selectedView = VIEWS_TYPE.MAIN;
  subscription: Subscription | undefined; // Para pasarselo a la tabla de la vista
  REFERENCE_TYPES = { General: 'general', Own: 'own' };

  columnGroups: Record<string, string[]> = {
    record: ['id', 'links'],
    owner: ['owner'],
    creation: ['createdAt'],
    creatorOrg: ['creatorOrg'],
    references: ['resources', 'references', 'myReferences'],
    invoice: ['invoices'],
    pickUp: ['pickUp'],
    delivery: ['delivery'],
    buyer: ['buyer'],
    supplier: ['supplier'],
    addressee: ['addressee'],
    documents: ['documents'],
    vendor: ['vendor'],
    taxes: ['taxes', 'mrn', 'status', 'statusDate'],
    transport: ['transportName', 'transportId', 'transportTypes'],
    transportOrigin: ['origin', 'destination', 'transportDocument', 'containers'],
    tact: ['tact'],
    transportDates: ['departureEstimated', 'departureActual', 'arrivalEstimated', 'arrivalActual'],
    lastEvent: ['lastEvent'],
    event: ['event'],
    eventStatusColumn: ['eventStatusColumn'],
    eventDate: ['eventDate'],
    eventCreatedAt: ['eventCreatedAt'],
    reference: ['resource', 'reference'],
  };

  groupsPerView = {
    [VIEWS_TYPE.MAIN]: ['owner', 'creation', 'creatorOrg', 'references', 'invoice', 'pickUp', 'delivery', 'buyer', 'vendor', 'supplier', 'addressee', 'documents', 'taxes', 'transport', 'transportDates', 'lastEvent'],
    [VIEWS_TYPE.TAXES]: ['owner', 'creation', 'creatorOrg', 'taxes', 'lastEvent'],
    [VIEWS_TYPE.TRANSPORT]: ['owner', 'transport', 'transportOrigin', 'tact', 'transportDates', 'lastEvent'],
    [VIEWS_TYPE.EVENTS]: ['owner', 'event', 'eventStatusColumn', 'eventDate', 'eventCreatedAt', 'reference', 'creation', 'creatorOrg'],
  };

  availableGroups = Object.keys(this.columnGroups);
  personalizedColumns: string[] = [];

  shownArchivedEvents = false;

  @ViewChild('input') input!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  constructor(private api: ApiService,
              private dialog: MatDialog,
              private ar: ActivatedRoute,
              public helper: HelperService,
              private cache: CacheService,
              private eventService: EventService,
              private router: Router) {

    // Ponemos aqui el contenido del OnInit para evitar el Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError que produce el spiner de carga
    this.cache.getUserPromise().then(user => {
      this.user = user;
      this.isGuest = !user.organization;
      if (!this.isGuest) {
        this.api.getLastUsage(user.organization!.id).subscribe(usage => {
          this.limit = this.helper.checkLimits(usage, this.user.organization!.subscription, 'records');
        });
      }
    });

    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe(async queryParams => {

      // Al transicionar entre vistas reseteo los datos para que las columnas no fallen (la carga de columnas es sincrona y la carga de datos asincrona)
      this.dataSource.data = [];

      // seteo el offset y el limit con los parámetros de la url, si no los pongo a cero o default
      this.query.pagination!.offset = queryParams.offset || 0;
      this.query.pagination!.limit = queryParams.limit || DEFAULT.PAGE_SIZE_OPTIONS[0];

      // Si no hay vista por defecto recargo inicializando
      if (!queryParams.view) return this.router.navigate([], { replaceUrl: true, queryParams: { view: VIEWS_TYPE.MAIN }, queryParamsHandling: 'merge' });
      else this.selectedView = queryParams.view;

      const user = await this.cache.getUserPromise();

      // Si el usuario tiene parametros en el perfil los cargo
      if (user.view[this.selectedView]) {
        const params: Record<string, string> = { ...queryParams };
        new URLSearchParams(user.view[this.selectedView]).forEach((value, key) => {
          // No persistimos special y actor xq no lo han pedido y nos obligaria a gestionar un tercer estado en la url
          if (!queryParams[key] && key !== 'special' && key !== 'actor') params[key] = value;
        });
        // Si hay distinto numero de argumentos es q he añadido asi que actualizo la url
        if (Object.keys(params).length !== Object.keys(queryParams).length) {
          await this.router.navigate([], { replaceUrl: true, queryParams: params, queryParamsHandling: 'merge' });
          return;
        }
      }


      if (!queryParams.status) return this.router.navigate([], { replaceUrl: true, queryParams: { status: 'active' }, queryParamsHandling: 'merge' });
      // Las columnas las pasamos a la url como un unico argumento llamado "columns" y de tipo string donde concatenamos todos los elementos del array con ",". Esto nos permite mantener el orden de los elementos ya que con un array en el query params no se mantienen
      if (!queryParams.columns) return this.router.navigate([], { replaceUrl: true, queryParams: { columns: this.groupsPerView[this.selectedView].join(',') }, queryParamsHandling: 'merge' });

      // seteamos el control y variable de las columnas personalizadas con los query
      this.personalizedColumnsControl.setValue(queryParams.columns.split(','));
      this.personalizedColumns = queryParams.columns.split(',');


      // Si estoy en una ruta distinta a la que el usuario tiene guardado, la actualizo
      if (user.view[this.selectedView] !== window.location.search) {
        user.view[this.selectedView] = window.location.search;
        this.api.editUser({ view: user.view }).subscribe(noop);
      }

      // Me guardo los valores de la url
      if (queryParams.special || queryParams.actor) this.selectFilterControl.setValue(queryParams.special || queryParams.actor); // Estas 2 claves van contra el mismo desplegable. Las manejamos de forma distinta xq el back lo hace asi
      if (queryParams.sort) this.query.sort = Array.isArray(queryParams.sort) ? queryParams.sort : [queryParams.sort]; // Pues resulta que si navegas desde la vista es un array pero si recargas es un string. Angular WTF.
      else delete this.query.sort;

      // Actualizo la vista con las columnas que toquen
      this.mountView();

      if (queryParams.special) this.query.filter!.special = queryParams.special;
      else delete this.query.filter!.special;
      if (queryParams.actor) this.query.filter!.actor = queryParams.actor;
      else delete this.query.filter!.actor;
      this.query.filter!.status = queryParams.status;
      this.archived = queryParams.status === 'archived';
      this.getRecords(this.query);

      return;
    });
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup').pipe(debounceTime(250)).subscribe(() => {
      this.query.filter!.search = this.input.nativeElement.value;
      this.getRecords(this.query);
    });
    this.personalizedColumnsControl.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(selectedColumns => {
      this.personalizedColumns = selectedColumns || [];
      this.router.navigate([], { replaceUrl: true, queryParams: { columns: this.personalizedColumns.join(',') }, queryParamsHandling: 'merge' });
    });
  }

  mountView() {
    this.displayedColumns = [...this.columnGroups['record']]; // Common columns

    // seteo el contenido del select de personalización de columnas
    this.availableGroups = this.groupsPerView[this.selectedView] || [];

    // Ordeno los disponibles con el orden de los marcados. Los elementos q no esten marcados mantienen el orden inicial
    this.availableGroups.sort((a, b) => {
      const i = this.personalizedColumns.indexOf(a);
      const j = this.personalizedColumns.indexOf(b);
      if (i === -1 || j === -1) return 0;
      if (i < j) return -1;
      else return 1;
    });

    // si la columna owner esta en la primera posición del array lo mete el primero en la tabla
    if (this.personalizedColumns[0] === 'owner') this.displayedColumns.unshift('owner');

    // si hay columnas personalizadas, mapeo el array para buscar en los grupos las que el user quiere meter
    this.personalizedColumns.forEach(key => {
      if (key !== 'owner' || this.personalizedColumns[0] !== 'owner') this.displayedColumns.push(...this.columnGroups[key]);
    });

    this.displayedColumns.push('action'); // Common columns
  }

  toggleRow(element: DashboardEntity) {
    const limits = element.limits;
    if (limits.main === 1) {
      Object.keys(limits).forEach(key => limits[key] = 100); // Pongo un limite alto para que se iteren todos los elementos de cada grupo
    } else {
      Object.keys(limits).forEach(key => limits[key] = 1); // Pliego todos los grupos
    }
  }

  getRecords(query: CustomQuery) {
    const view = this.selectedView;
    const columns = this.personalizedColumns.reduce((columns, column) => ({ ...columns, [column]: true }), {});
    if (this.subscription) this.subscription.unsubscribe();

    this.subscription = this.api.getDashboard(query, view).subscribe(res => {
      // Si se añaden columnas desplegables nuevas habra q añadir aqui su limite
      res.data.forEach(elem => elem.limits = { main: 1, taxes: 1, pickUp: 1, delivery: 1, transport: 1, origin: 1, destination: 1, resources: 1, invoices: 1 });
      if (view === VIEWS_TYPE.MAIN) this.dataSource.data = res.data;
      // Esto es una ñapa para que cuadre el formato con la vista general y sea reutilizable.
      if (view === VIEWS_TYPE.TAXES) this.dataSource.data = res.data.map((element) => ({ ...element, recordTaxesData: [element] }));
      if (view === VIEWS_TYPE.TRANSPORT) this.dataSource.data = res.data.map((element) => ({ ...element, recordTransportData: [element] }));
      if (view === VIEWS_TYPE.EVENTS) this.dataSource.data = res.data.map((element) => ({ ...element, recordEventsData: [element] }));

      this.totalElements = res.total;
      // calculamos la página en la que está el record, si el total de records es menor que el offset, le seteamos cero
      if (res.total < query.pagination?.offset!) this.router.navigate([], { replaceUrl: true, queryParams: { offset: 0 }, queryParamsHandling: 'merge' });
      else this.paginator.pageIndex = query.pagination?.offset! / this.paginator.pageSize;
    });
  }

  getPage(event: PageEvent) {
    // en vez de hacer la petición con la query, seteamos los queryparams y navegamos con los datos del evento
    this.router.navigate([], { replaceUrl: true, queryParams: { limit: event.pageSize, offset: event.pageSize * event.pageIndex }, queryParamsHandling: 'merge' });
  }

  openSaveTemplateModal(recordId: string) {
    this.dialog.open(SaveTemplateModalComponent, { data: { recordId: recordId }, ...MODAL_WIDTH, disableClose: true });
  }

  archiveRecord(recordId: string) {
    this.api.archiveRecord(recordId, this.archived).subscribe(() => {
      this.api.log('toast.archived');
      this.getRecords(this.query);
    });
  }

  openTemplateModal() {
    if (this.limit?.step === 'in-limit') return;
    this.dialog.open(TemplateSelectionModalComponent, { ...MODAL_WIDTH, disableClose: true });
  }

  sortRecords(sortState: Sort) {
    const query = this.helper.sortItems(sortState);
    this.getRecords(query);
  }

  duplicateRecord(recordId: string) {
    this.eventService.loadingOn(this.api.cloneRecord(recordId)).subscribe({
      next: (res) => {
        this.api.log('recordList.tooltips.cloned');
        this.router.navigate(['/record-detail', res.data.id, 'canvas']);
      },
      error: () => {
        this.api.logWarn('toast.OrganizationProductLimit', true, { products: this.limit?.i18n.products });
      },
    });
  }

  showEventMetadata(elementMetadata: EventMetadata) {
    this.dialog.open(EventMetadataModalComponent, { ...MODAL_WIDTH_SMALL, maxHeight: '80vh', data: elementMetadata });
  }

  removeRecordPrefix = (value: string) => value?.replace('record-', '') ?? value;
}
