import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';

import { ApiService } from '../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../common/shared/custom-validators';
import { MainEventService } from '../../../../../common/shared/main-event.service';
import { CacheService } from '../../core/services/cache.service';
import { EnvService } from '../../core/services/env.service';

@UntilDestroy()
@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
})

export class LoginSsoComponent implements OnInit {

  loginSSOForm = new FormControl<string>('', [Validators.required, CustomValidators.validateDomain]);

  return: string = '/';

  constructor(private env: EnvService, private translate: TranslateService, private fb: FormBuilder, private router: Router, private api: ApiService, public dialog: MatDialog, private ar: ActivatedRoute, private reCaptchaV3Service: ReCaptchaV3Service, private cache: CacheService) {
  };

  ngOnInit(): void {
    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.token) this.sendLogin(params.token);
      if (params.error) {
        const translatedError = this.translate.instant('errorsHTTP.' + params.error);
        this.api.logError(translatedError);
      }
    });
  }

  sendLogin(token: string) {
    this.api.loginSSOToken(token).subscribe(res => {
      this.cache.setUser(res.user);
      MainEventService.login.emit(res);
      this.router.navigateByUrl(this.return);
    });
  }

  sendSSO() {
    const domain = this.loginSSOForm.getRawValue();
    const encodedEmail = encodeURIComponent(domain ?? '');
    window.location.href = `${this.api.endpoint}/auth/login/sso?domain=${encodedEmail}`;
  }

}
