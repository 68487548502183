import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApplicationsFormComponent } from '../../../common/components/applications-form/applications-form.component';
import { Error404Component } from '../../../common/components/error-404/error-404.component';
import { CanDeactivateGuard, Logged, OrganizationOwner, Registered } from './core/guard/auth.guard';
import { ChatDetailComponent } from './routes/chats/chat-detail/chat-detail.component';
import { ChatListComponent } from './routes/chats/chat-list/chat-list.component';
import { FavoritesComponent } from './routes/favorites/favorites.component';
import { HomeComponent } from './routes/home/home.component';
import { IframeComponent } from './routes/iframe/iframe.component';
import { LoginComponent } from './routes/login/login.component';
import { PasswordRecoveryComponent } from './routes/login/password-recovery/password-recovery.component';
import { ValidateInvitationComponent } from './routes/login/validate-invitation/validate-invitation.component';
import { LoginGuestComponent } from './routes/login-guest/login-guest.component';
import { LoginSsoComponent } from './routes/login-sso/login-sso.component';
import { OnboardingComponent } from './routes/onboarding/onboarding.component';
import { NotificationsConfigurationComponent } from './routes/profile/notifications-configuration/notifications-configuration.component';
import { OrganizationComponent } from './routes/profile/organization/organization.component';
import { OrganizationEventsComponent } from './routes/profile/organization-events/organization-events.component';
import { OrganizationPermissionsComponent } from './routes/profile/organization-permissions/organization-permissions.component';
import { OrganizationUsageComponent } from './routes/profile/organization-usage/organization-usage.component';
import { ProfileComponent } from './routes/profile/profile.component';
import { UserDataComponent } from './routes/profile/user-data/user-data.component';
import { RecordListComponent } from './routes/record-list/record-list.component';
import { RecordNotFoundComponent } from './routes/record-not-found/record-not-found.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'smartgp', component: LoginComponent },
  { path: 'login-guest', component: LoginGuestComponent },
  { path: 'login-sso', component: LoginSsoComponent },
  { path: 'onboarding', component: OnboardingComponent },
  { path: 'validate-email', component: OnboardingComponent },
  { path: 'validate-invitation', component: ValidateInvitationComponent },
  { path: 'doc-guardian', loadChildren: () => import('./modules/doc-guardian-module/doc-guardian-module.module').then(m => m.DocGuardianModuleModule) },

  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          { path: 'user-data', component: UserDataComponent },
          { path: 'applications', component: ApplicationsFormComponent },
          { path: 'permissions', component: OrganizationPermissionsComponent },
          { path: 'organization', component: OrganizationComponent, canActivate: [OrganizationOwner] },
          { path: 'organization-usage', component: OrganizationUsageComponent, canActivate: [OrganizationOwner] },
          { path: 'notifications-configuration', component: NotificationsConfigurationComponent, canDeactivate: [CanDeactivateGuard] },
          { path: 'events', component: OrganizationEventsComponent },
        ],
      },
      { path: 'onboarding', component: OnboardingComponent },
      { path: 'password-recovery', component: PasswordRecoveryComponent },
      { path: '', redirectTo: '/record-list', pathMatch: 'full' },
      { path: 'record-list', component: RecordListComponent },
      { path: 'record-detail', loadChildren: () => import('./modules/record-detail-module/record-detail-module.module').then(m => m.RecordDetailModuleModule) },
      { path: 'record-not-found/:id', component: RecordNotFoundComponent },
      { path: 'favorites', component: FavoritesComponent, canActivate: [Registered] },
      { path: 'messages', component: ChatListComponent },
      { path: 'messages/:id', component: ChatDetailComponent },
      { path: 'iframe', component: IframeComponent },

    ],
    canActivate: [Logged],
  },
  { path: '404', component: Error404Component },
  { path: '**', component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
